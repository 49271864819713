import { default as _91_46_46_46page_93iwSoHGymtiMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/public-portal/pages/[...page].vue?macro=true";
import { default as bedanktq4Ji3xmtr1Meta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/public-portal/pages/bedankt.vue?macro=true";
import { default as indexPEeb5IkfdeMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/public-portal/pages/index.vue?macro=true";
import { default as wifi_45connectLYrfc8XdMSMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/public-portal/pages/wifi-connect.vue?macro=true";
export default [
  {
    name: _91_46_46_46page_93iwSoHGymtiMeta?.name ?? "page",
    path: _91_46_46_46page_93iwSoHGymtiMeta?.path ?? "/:page(.*)*",
    meta: _91_46_46_46page_93iwSoHGymtiMeta || {},
    alias: _91_46_46_46page_93iwSoHGymtiMeta?.alias || [],
    redirect: _91_46_46_46page_93iwSoHGymtiMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/public-portal/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: bedanktq4Ji3xmtr1Meta?.name ?? "bedankt",
    path: bedanktq4Ji3xmtr1Meta?.path ?? "/bedankt",
    meta: bedanktq4Ji3xmtr1Meta || {},
    alias: bedanktq4Ji3xmtr1Meta?.alias || [],
    redirect: bedanktq4Ji3xmtr1Meta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/public-portal/pages/bedankt.vue").then(m => m.default || m)
  },
  {
    name: indexPEeb5IkfdeMeta?.name ?? "index",
    path: indexPEeb5IkfdeMeta?.path ?? "/",
    meta: indexPEeb5IkfdeMeta || {},
    alias: indexPEeb5IkfdeMeta?.alias || [],
    redirect: indexPEeb5IkfdeMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/public-portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: wifi_45connectLYrfc8XdMSMeta?.name ?? "wifi-connect",
    path: wifi_45connectLYrfc8XdMSMeta?.path ?? "/wifi-connect",
    meta: wifi_45connectLYrfc8XdMSMeta || {},
    alias: wifi_45connectLYrfc8XdMSMeta?.alias || [],
    redirect: wifi_45connectLYrfc8XdMSMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/110/nuxt/public-portal/pages/wifi-connect.vue").then(m => m.default || m)
  }
]